// import { Toast } from "antd-mobile";
export function noop() {
}

function Toast() {
}

let base_url = "/api";

function getHeaders(headers = {}) {
  let _headers = {...headers};

  let token = localStorage.getItem("token");
  if (!!token) _headers.token = token;
  return _headers;
}

function requestInterceptor(res) {
  if (res && res.code == 1000) {
    // user not login
    localStorage.removeItem("token");
  }
}

let unloginHandler = noop;

export function registerUnloginHandler(handler) {
  unloginHandler = handler;
}

//data传入必须是对象
export function get(url, callback = noop) {
  url = `${base_url}${url}`
  return fetch(url, {method: "GET", headers: getHeaders()})
    .then((response) => response.json())
    .then(res => {
      requestInterceptor(res);
      if (callback != noop) {
        callback(res);
      }
      if (!res) {
        Toast.show({content: "Network error"})
        return;
      }
      res.code == 1000 && unloginHandler();
      if (res.code != 0) {
        Toast.show({content: res.message});
        return undefined;
      }
      return res.data
    })
    .catch(err => {
      Toast.show({content: err.message});
      console.log(err)
    })
}

export function post(url, body, callback = noop) {
  url = `${base_url}${url}`
  if (typeof body === 'object') body = JSON.stringify(body);
  return fetch(url, {method: "POST", body, headers: getHeaders({'Content-Type': 'application/json'})})
    .then((response) => response.json())
    .then(res => {
      requestInterceptor(res);
      if (callback != noop) {
        callback(res);
      } else {
        if (!res) {
          Toast.show({content: "Network error"})
          return;
        }
        res.code == 1000 && unloginHandler();
        if (res.code != 0) {
          Toast.show({content: res.message});
          return undefined;
        }
        return res.data
      }
    })
    .catch(err => {
      Toast.show({content: err.message});
      console.log(err)
    })
}

export function openWindow(url) {
  window.open(base_url + url, "_black");
}

export function simpleHandler(data = {}) {
  if (data.message) Toast.show({content: data.message})
}

export function debounce(fn, wait = 200) {
  let timer
  return function () {
    let context = this
    let args = arguments
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, wait)
  }
}

export function copyToClipboard(t) {
  // 如果当前浏览器版本不兼容navigator.clipboard
  if (!navigator.clipboard) {
    var ele = document.createElement("input");
    ele.value = t;
    document.body.appendChild(ele);
    ele.select();
    document.execCommand("copy");
    document.body.removeChild(ele);
    if (document.execCommand("copy")) {
      console.log("复制成功！");
    } else {
      console.log("复制失败！");
    }
  } else {
    navigator.clipboard.writeText(t).then(function () {
      console.log("复制成功！");
    }).catch(function () {
      console.log("复制失败！");
    })
  }
}

export function format(date, fmt) {
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "H+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

/**
 * 根据指定的条件判断给定的对象是否满足要求
 * @param {object} obj
 * @param {object} valueContain 对象中含有该值
 * @returns {bool}
 */
export function objValueContain(obj, valueContain) {
  if (!valueContain) {
    return true
  }
  for (let key in obj) {
    let val = obj[key];
    if (Array.isArray(val)) {
      for (let _v of val) {
        if (objValueContain(_v, valueContain)) return true;
      }
    }
    if (!!val && `${val}`.indexOf(valueContain) != -1) return true;
  }
  return false;
}

export function getDefaultDeliveryDate() {
  let _date = new Date();
  let date = Number.parseInt(format(_date, "yyyyMMdd"));
  if (new Date().valueOf() > new Date(format(_date, "yyyy-MM-dd 20:55:00")).valueOf()) {
    date++;
  }
  return date;
}
