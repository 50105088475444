const menu = [
  {name: "Introduction", url: "introduction"},
  {name: "YYT collection", url: "yyt-collection"},
  {name: "Art sites", url: "art-sites"},
  {name: "Exhibition", url: "exhibition"},
  {name: "Public art", url: "public-art"},
  {name: "R&D", url: "rd"},
  {name: "Charity", url: "charity"},
  {name: "Shiyuan Studio", url: "shiyuan-studio"},
]

export default menu
