import {layoutSlice} from '@/lib/redux'

export const showHeader =
  (show) =>
    async (dispatch, getState) => {
      dispatch(layoutSlice.actions.setHeaderShow(show))
    }

export const showNav =
  (show) =>
    async (dispatch, getState) => {
      dispatch(layoutSlice.actions.setNavShow(show))
    }
