import React from 'react'
import "@/styles/bottom.scss"

export default function Bottom({className}) {
  return <div className={`bottom ${className}`}>
    <div className='btm-logo-container'>
      <img src='/res/logo/YIYUTANGNG.png'/>
    </div>
    <div className='btm-info'>
      <a href='mailto:gs@yiyuantang.org'>Contact</a>
    </div>
  </div>
}
